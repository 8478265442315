<template>
  <div style="border: 1px solid #ccc">
    <Toolbar style="border-bottom: 1px solid #ccc" :editor="editorRef" :defaultConfig="toolbarConfig" :mode="mode" />
    <Editor style="height: 500px; overflow-y: hidden" v-model="valueHtml" :defaultConfig="editorConfig" :mode="mode" @onCreated="handleCreated" :key="indexValue" />
  </div>
</template>

<script>
import '@wangeditor/editor/dist/css/style.css' // 引入 css

import { onBeforeUnmount, ref, shallowRef, onMounted, watch } from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { upLoadImg } from '@/api/active'

export default {
  name: 'editorRich',
  components: { Editor, Toolbar },
  props: {
    htmlValue: {
      type: String,
      default: '',
    },
    indexValue: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    // 编辑器实例，必须用 shallowRef
    const editorRef = shallowRef()

    // 内容 HTML
    const valueHtml = ref('')

    // watch(
    //   () => (newValue, oldValue) => {

    //   },
    //   { deep: true, immediate: true }
    // )

    watch(
      [() => props.htmlValue, () => valueHtml],
      ([newValue1, newValue2]) => {
        valueHtml.value = newValue1
        const editor = editorRef.value
        if (editor) {
          emit('contentChange', editor.getHtml(), props.indexValue)
        }
        // console.log('property2 changed to', newValue2.value)
      },
      { deep: true, immediate: true }
    )

    // 模拟 ajax 异步获取内容
    onMounted(() => {})

    const toolbarConfig = {
      excludeKeys: ['fullScreen', 'uploadVideo'],
    }
    const editorConfig = {
      placeholder: '请输入内容...',
      MENU_CONF: {
        uploadImage: {
          async customUpload(file, insertFn) {
            console.log(file)
            let upData = new FormData()
            upData.append('file', file)
            const { data } = await upLoadImg(upData)
            insertFn(data, '图片', '')
          },
        },
      },
    }

    const setValue = (nval) => {
      const editor = editorRef.value
      editor.setHtml(nval.options.list[0].richText)
      // if (editor == null) return
      // editor.destroy()
    }

    const handleCreated = (editor) => {
      editorRef.value = editor // 记录 editor 实例，重要！
      console.log(editorRef.value.getAllMenuKeys())
    }

    const customUpload = () => {
      console.log('dddd')
    }

    return {
      editorRef,
      valueHtml,
      mode: 'default', // 或 'simple'
      toolbarConfig,
      editorConfig,
      handleCreated,
      setValue
    }
  },
}
</script>
